import * as React from 'react';
import { useRef, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';

import liff from '@line/liff';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import { createAdvancePay } from '../features/advancePay/advancePaySlice';
import { userId } from '../utils/liff';
import { useAppDispatch } from '../app/hooks';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';

// @formatter:off
const steps = ['1', '2', '3', '4', '5', '6'];

class AdvancePayFormProps {
  profile: any;
  created: boolean | undefined;
}

const AdvancePayForm: React.FC<AdvancePayFormProps> = ({
  profile,
  created,
}) => {
  // interface formName {
  //   lastname: string;
  //   firstname: string;
  //   staffCode: string;
  //   advancePayType: string;
  //   advancePayBranch: string;
  // }

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      lastname: profile.lastname,
      firstname: profile.firstname,
      staffCode: profile.staffCode,
      ready: 'yes',
      advancePayType: 'デジシート',
      advancePayBranch: profile.advancePayBranch || '',
      advancePayFileUrl: '',
    },
    errors: {},
    touched: {},
  });

  const handleChange = (event: any, field: any, value: any) => {
    // event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [field]: value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed] = React.useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const dispatch = useAppDispatch();
  const [creating, setCreating] = React.useState(false);
  const submit = () => {
    setCreating(true);
    dispatch(
      createAdvancePay({
        userId: userId(),
        values: formState.values,
      }),
    );
  };

  // console.log(created);
  if (created && activeStep !== 6) {
    setCreating(false);
    setActiveStep(6);
  }

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [open2, setOpen2] = React.useState(false);
  const handleClose2 = () => {
    setOpen2(false);
  };
  const [open3, setOpen3] = React.useState(false);
  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleNext = () => {
    console.log(activeStep);
    let specialAdd = 0;
    if (activeStep === 0) {
      if (
        formState.values.advancePayType === 'FAX-OCRシート' ||
        formState.values.advancePayType === 'その他タイムカード'
      ) {
        specialAdd = 1;
      }
    }

    if (activeStep === 1) {
      if (formState.values.ready === 'no') {
        setOpen(true);
        return;
      }
    }

    if (activeStep === 2) {
      if (
        formState.values.advancePayType !== 'FAX-OCRシート' &&
        formState.values.advancePayType !== 'その他タイムカード'
      ) {
        specialAdd = 1;
      }
    }

    if (activeStep === 3) {
      if (!formState.values.advancePayFileUrl) {
        setOpen2(true);
        return;
      }
    }

    if (activeStep === 4) {
      if (!formState.values.advancePayBranch) {
        setOpen3(true);
        return;
      }
    }

    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1 + specialAdd;
    setActiveStep(newActiveStep);
    window.scrollTo({
      top: 0,
    });
  };
  const handleBack = () => {
    console.log(activeStep);
    let specialAdd = 0;
    if (activeStep === 2) {
      if (
        formState.values.advancePayType === 'FAX-OCRシート' ||
        formState.values.advancePayType === 'その他タイムカード'
      ) {
        specialAdd = 1;
      }
    }

    if (activeStep === 4) {
      if (
        formState.values.advancePayType !== 'FAX-OCRシート' &&
        formState.values.advancePayType !== 'その他タイムカード'
      ) {
        specialAdd = 1;
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1 - specialAdd);
    window.scrollTo({
      top: 0,
    });
  };

  const fileInputRef = useRef(null);
  const onChangeFile = (event: any) => {
    const data = new FormData();
    data.append('file', event.target.files[0]);

    setCreating(true);

    axios
      .post(`https://storage-staging.littlehelp.co.jp/5856445/upload`, data)
      .then((res) => {
        console.log(res.data.url);
        handleChange(
          {
            target: {
              name: 'advancePayFileUrl',
            },
          },
          'advancePayFileUrl',
          res.data.url,
        );

        setCreating(false);
      })
      .catch((error) => {
        console.log(error);
        setCreating(false);
      });
  };

  const branches = [
    '',
    '札幌支店',
    '千歳支店',
    '米沢支店',
    '郡山支店',
    '長岡支店',
    '新潟支店',
    '古河支店',
    '筑西支店',
    'つくば支店',
    '水戸支店',
    '久喜支店',
    '仙台支店',
    '千葉支店',
    '福岡支店',
    '京都支店',
    '静岡支店',
    '名古屋支店',
    '四日市支店',
    '大阪支店',
    '難波支店',
    '神戸支店',
    '高松支店',
    '松山支店',
    '広島支店',
    '北九州支店',
    '熊本支店',
    '宮崎支店',
    '鹿児島支店',
    '清原支店',
    '那須ファクトリー支店',
    '小山ファクトリー支店',
    '宇都宮ファクトリー支店',
    '情報の森支店',
    '那須オフィス支店',
    '小山オフィス支店',
    '宇都宮オフィス支店',
    '宇都宮中央支店',
    '足利支店',
    '熊谷支店',
    '高崎支店',
    '伊勢崎支店',
    'DR小山支店',
    'DR高崎支店',
    'DRさいたま支店',
    'DR所沢支店',
    'DR船橋支店',
    'DR新宿支店',
    'DR横浜支店',
    'DR厚木支店',
    'DR名古屋支店',
    '日本橋支店',
    '首都圏金融支店',
    '新宿支店',
    '武蔵小杉支店',
    '立川支店',
    '横浜支店',
    '厚木支店',
    '所沢支店',
    'さいたま支店',
    '船橋支店',
    '柏支店',
    '首都圏支店',
  ];

  return (
    <span>
      <AppBar
        position="static"
        elevation={0}
        style={{
          alignItems: 'center',
          backgroundColor: '#6ba5b4',
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div">
            {activeStep === 0 ? '前給の申請' : ''}
            {activeStep === 1 ? '勤怠入力の確認' : ''}
            {activeStep === 2 ? '申請者の確認' : ''}
            {activeStep === 3 ? '画像のアップロード' : ''}
            {activeStep === 4 ? '支店/事業所名の選択' : ''}
            {activeStep === 5 ? '確認画面' : ''}
            {activeStep === 6 ? '受付完了' : ''}
          </Typography>
        </Toolbar>
      </AppBar>
      {creating ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ m: 2 }}>
          {/*<Stepper*/}
          {/*  activeStep={activeStep}*/}
          {/*  alternativeLabel*/}
          {/*  sx={{ display: activeStep === 0 ? 'none' : '' }}*/}
          {/*>*/}
          {/*  {steps.map((label) => {*/}
          {/*    const stepProps: { completed?: boolean } = {};*/}
          {/*    const labelProps: {*/}
          {/*      optional?: React.ReactNode;*/}
          {/*    } = {};*/}
          {/*    return (*/}
          {/*      <Step key={label} {...stepProps}>*/}
          {/*        <StepLabel {...labelProps} />*/}
          {/*      </Step>*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</Stepper>*/}
          {activeStep === 0 ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                <div
                  style={{
                    color: '#696969',
                    fontSize: '13px',
                    marginBottom: '1em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right',
                  }}
                >
                  {moment().format('YYYY年M月D日')}
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img src="/randstad-logo.png" alt="logo" width="80%" />
                </div>
                <br />
                平日午前10時までに申請ください。
                <br />
                <br />
                勤務先で使用している勤怠提出方法を選択ください。
                <br />
                <br />
                <div>
                  <FormControl sx={{ ml: 2, width: '80%' }}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={formState.values.advancePayType}
                      onChange={(event) => {
                        console.log(event.target.value);
                        handleChange(
                          event,
                          'advancePayType',
                          event.target.value,
                        );

                        if (
                          event.target.value === 'デジシート' ||
                          event.target.value === 'e-staing(e-Time Card)'
                        ) {
                          handleChange(
                            {
                              target: {
                                name: 'advancePayFileUrl',
                              },
                            },
                            'advancePayFileUrl',
                            null,
                          );
                        }
                      }}
                    >
                      <FormControlLabel
                        value="デジシート"
                        control={<Radio />}
                        label="デジシート"
                      />
                      <FormControlLabel
                        value="e-staing(e-Time Card)"
                        control={<Radio />}
                        label="e-staing(e-Time Card)"
                      />
                      <FormControlLabel
                        value="FAX-OCRシート"
                        control={<Radio />}
                        label="FAX-OCRシート"
                      />
                      <FormControlLabel
                        value="その他タイムカード"
                        control={<Radio />}
                        label="その他タイムカード"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <br />
                ※FAX-OCRシート、その他タイムカードを選択した場合は次の画面でその勤怠カードの写真の添付が必要です。
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {activeStep === 1 ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                申請前に、必ずご自身の該当の勤怠の入力および申請が完了しているかどうかを確認ください。
                <br />
                <br />
                勤怠の入力が完了していない場合は前給の処理ができない、もしくは確認のため遅れてしまう可能性がありますのでご了承ください。
                <br />
                <br />
                勤怠の入力は完了していますか？
                <br />
                <br />
                <div>
                  <FormControl sx={{ ml: 2, width: '80%' }}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={formState.values.ready}
                      onChange={(event) => {
                        console.log(event.target.value);
                        handleChange(event, 'ready', event.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="はい"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="いいえ"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {activeStep === 2 ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                確認のため、申請者ご自身のスタッフコード、および氏名に間違えがないかどうか確認ください。
                <br />
                <br />
                <div>
                  <TextField
                    id="staff-code"
                    variant="standard"
                    label="スタッフコード"
                    type="tel"
                    placeholder="00000000"
                    value={formState.values.staffCode}
                    inputProps={{ maxLength: '8' }}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'staffCode', event.target.value);
                    }}
                    sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '81%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div>
                  <TextField
                    id="standard-basic"
                    label="姓"
                    variant="standard"
                    value={formState.values.lastname}
                    onChange={(event) => {
                      handleChange(event, 'lastname', event.target.value);
                    }}
                    sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
                    placeholder="山田"
                  />
                  <TextField
                    id="standard-basic"
                    label="名"
                    variant="standard"
                    value={formState.values.firstname}
                    onChange={(event) => {
                      handleChange(event, 'firstname', event.target.value);
                    }}
                    sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
                    placeholder="太郎"
                  />
                </div>
                <br />
                ※間違いがある場合は修正後に次へ進んでください。
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {activeStep === 3 ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                手元にあるFAX-OCRシート、その他タイムカードを写真に取り、下記のアップロードより添付ください。
                <br />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '1em',
                  }}
                >
                  <Button
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      fileInputRef.current.click();
                    }}
                    style={{
                      width: '100%',
                    }}
                  >
                    {formState.values.advancePayFileUrl ? (
                      <Avatar
                        alt="Icon"
                        src={formState.values.advancePayFileUrl}
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: 'unset',
                        }}
                      />
                    ) : (
                      <Button
                        color="inherit"
                        variant={'contained'}
                        sx={{
                          marginTop: 1,
                          width: '100%',
                          backgroundColor: '#1d6ad4',
                          color: '#fff',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            width: '100%',
                          }}
                        >
                          {'画像をアップロードする'}
                        </span>
                      </Button>
                    )}
                  </Button>
                </div>
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {activeStep === 4 ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                まだ完了ではありません。
                <br />
                <br />
                あなたを担当している支店/事業所名を選択ください。
                <br />
                <br />
                <div>
                  <InputLabel
                    sx={{ ml: 2, mt: 2, color: '#0f1941', fontSize: '1em' }}
                    id="jobCategory-label"
                  >
                    {'担当支店'}
                  </InputLabel>
                  <FormControl
                    sx={{ ml: 2, mt: 1, mr: 2, mb: 2, width: '80%' }}
                  >
                    <Select
                      labelId="jobCategory-label"
                      id="jobCategory-select"
                      variant="standard"
                      value={formState.values.advancePayBranch}
                      onChange={(event) => {
                        console.log(event.target.value);
                        handleChange(
                          event,
                          'advancePayBranch',
                          event.target.value,
                        );
                      }}
                    >
                      {branches.map((branch) => {
                        return <MenuItem value={branch}>{branch}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </div>
                <br />
                ※担当支店が変更になった場合は上記も選びなおしてください。
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {activeStep === 5 ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                最後に申請内容を確認ください。
                <br />
                <br />
                申請者情報
                <br />
                スタッフコード： <b>{formState.values.staffCode}</b>
                <br />
                姓： <b>{formState.values.lastname}</b>
                {'　'}名：<b>{formState.values.firstname}</b>
                <br />
                <br />
                提出する勤怠：<b>{formState.values.advancePayType}</b>
                <br />
                担当支店： <b>{formState.values.advancePayBranch}</b>
                {formState.values.advancePayFileUrl ? (
                  <div>
                    <br />
                    <Avatar
                      alt="Icon"
                      src={formState.values.advancePayFileUrl}
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: 'unset',
                      }}
                    />
                  </div>
                ) : null}
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {activeStep === 6 ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                前給の申請を受付ました。お手続きありがとうございます。
                <br />
                <br />
                申請日時： <b>{moment().format('M月D日 HH時mm分')}</b>
                <br />
                <br />
                なお、申請の受付については上記申請日時を基準に処理を進めてまいります。
                <br />
                <br />
                当日の受付時間を過ぎている場合は翌日受付での処理となりますのでご了承ください。
                <br />
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          <Grid
            item
            xs={7}
            style={{
              textAlign: 'center',
            }}
          >
            <AppBar
              position="fixed"
              color="primary"
              sx={{
                top: 'auto',
                bottom: -2,
                backgroundColor: '#1d6ad4',
              }}
            >
              <Grid
                container
                style={{
                  height: 55,
                }}
              >
                {activeStep === 6 ? (
                  <React.Fragment>
                    <Grid
                      item
                      sx={{ width: '100%' }}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        color="inherit"
                        aria-label="next key"
                        sx={{
                          marginTop: 1,
                          width: '100%',
                        }}
                        /*disabled={applied}*/
                        onClick={() => {
                          liff.closeWindow();
                        }}
                      >
                        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                          {'完了'}
                        </span>
                      </Button>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Grid
                      item
                      xs={6}
                      style={{
                        backgroundColor: '#d9d9d9',
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        color="inherit"
                        aria-label="back key"
                        sx={{
                          backgroundColor: '#d9d9d9',
                          marginTop: 1,
                          width: '100%',
                          color: '#1d6ad4',
                        }}
                        disabled={activeStep === 0 || activeStep === 6}
                        onClick={handleBack}
                      >
                        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                          前へ
                        </span>
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <Button
                        color="inherit"
                        aria-label="next key"
                        sx={{
                          marginTop: 1,
                          width: '100%',
                        }}
                        onClick={
                          activeStep === 5
                            ? submit
                            : activeStep === 6
                            ? () => {
                                liff.closeWindow();
                              }
                            : handleNext
                        }
                      >
                        <span
                          style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            width: '50%',
                          }}
                        >
                          {activeStep === 5
                            ? '申請する'
                            : activeStep === 6
                            ? '完了'
                            : '次へ'}
                        </span>
                      </Button>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              {/*</Toolbar>*/}
            </AppBar>
          </Grid>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                恐れ入りますがデジシートもしくはe-staffingへ勤怠を入力後に再度申請を進めてください。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>

          <input
            ref={fileInputRef}
            type="file"
            accept="image/jpg,image/png,image/jpeg"
            onChange={onChangeFile}
            style={{
              display: 'none',
            }}
          />

          <Dialog
            open={open2}
            onClose={handleClose2}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                画像がアップロードされていません。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose2} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={open3}
            onClose={handleClose3}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                担当支店が選択されていません。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose3} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </span>
  );
};

export default AdvancePayForm;
