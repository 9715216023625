import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import axios from 'axios';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL || '';

export interface ProfileState {
  profile: any;
  status: 'idle' | 'loading' | 'failed';
  loaded: boolean;
  professionalLocations: [];
  professionalLocationsLoaded: boolean;
  professionalIndustries: [];
  professionalIndustriesLoaded: boolean;
  professionalPositions: [];
  professionalPositionsLoaded: boolean;
}

const initialState: ProfileState = {
  profile: null,
  status: 'idle',
  loaded: false,
  professionalLocations: [],
  professionalLocationsLoaded: false,
  professionalIndustries: [],
  professionalIndustriesLoaded: false,
  professionalPositions: [],
  professionalPositionsLoaded: false,
};

export const saveProfile = createAsyncThunk(
  'saveProfile',
  async (params: any) => {
    const response = await axios.post(
      `${API_SERVER_URL}/services/randstad/recommend/profile/${params.userId}`,
      params.values,
    );
    return response.data;
  },
);

export const savePin = createAsyncThunk('savePin', async (params: any) => {
  const response = await axios.post(
    `${API_SERVER_URL}/services/randstad/recommend/profile/${params.userId}/pin/${params.number}`,
  );
  return response.data;
});

export const saveProfessionalPin = createAsyncThunk(
  'saveProfessionalPin',
  async (params: any) => {
    const response = await axios.post(
      `${API_SERVER_URL}/services/randstad/recommend/profile/${params.userId}/pin/professional/${params.number}`,
    );
    return response.data;
  },
);

export const removePin = createAsyncThunk('removePin', async (params: any) => {
  const response = await axios.delete(
    `${API_SERVER_URL}/services/randstad/recommend/profile/${params.userId}/pin/${params.number}`,
  );
  return response.data;
});

export const removeProfessionalPin = createAsyncThunk(
  'removeProfessionalPin',
  async (params: any) => {
    const response = await axios.delete(
      `${API_SERVER_URL}/services/randstad/recommend/profile/${params.userId}/pin/professional/${params.number}`,
    );
    return response.data;
  },
);

export const applyJob = createAsyncThunk('applyJob', async (params: any) => {
  const response = await axios.post(
    `${API_SERVER_URL}/services/randstad/recommend/apply/${params.userId}/job/${params.number}`,
  );
  return response.data;
});

export const retrieveProfile = createAsyncThunk(
  'retrieveProfile',
  async (params: any) => {
    const response = await axios.get(
      `${API_SERVER_URL}/services/randstad/recommend/profile/${params.userId}`,
    );
    return response.data;
  },
);

export const retrieveProfileLocations = createAsyncThunk(
  'retrieveProfileLocations',
  async () => {
    const response = await axios.get(
      `${API_SERVER_URL}/services/randstad/recommend/profile/professional/locations`,
    );
    return response.data;
  },
);

export const retrieveProfileIndustries = createAsyncThunk(
  'retrieveProfileIndustries',
  async () => {
    const response = await axios.get(
      `${API_SERVER_URL}/services/randstad/recommend/profile/professional/industries`,
    );
    return response.data;
  },
);

export const retrieveProfilePositions = createAsyncThunk(
  'retrieveProfilePositions',
  async () => {
    const response = await axios.get(
      `${API_SERVER_URL}/services/randstad/recommend/profile/professional/positions`,
    );
    return response.data;
  },
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    hello: (state) => {
      console.log(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(retrieveProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(retrieveProfile.fulfilled, (state, action) => {
        state.status = 'idle';
        state.profile = action.payload;
        state.loaded = true;
      });

    builder.addCase(savePin.fulfilled, (state) => {
      state.status = 'idle';
      state.loaded = false;
    });

    builder.addCase(saveProfessionalPin.fulfilled, (state) => {
      state.status = 'idle';
      state.loaded = false;
    });

    builder.addCase(removePin.fulfilled, (state) => {
      state.status = 'idle';
      state.loaded = false;
    });

    builder.addCase(removeProfessionalPin.fulfilled, (state) => {
      state.status = 'idle';
      state.loaded = false;
    });

    builder
      .addCase(retrieveProfileLocations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(retrieveProfileLocations.fulfilled, (state, action) => {
        state.status = 'idle';
        state.professionalLocations = action.payload;
        state.professionalLocationsLoaded = true;
      });

    builder
      .addCase(retrieveProfileIndustries.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(retrieveProfileIndustries.fulfilled, (state, action) => {
        state.status = 'idle';
        state.professionalIndustries = action.payload;
        state.professionalIndustriesLoaded = true;
      });

    builder
      .addCase(retrieveProfilePositions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(retrieveProfilePositions.fulfilled, (state, action) => {
        state.status = 'idle';
        state.professionalPositions = action.payload;
        state.professionalPositionsLoaded = true;
      });
  },
});

export const { hello } = profileSlice.actions;

export const selectProfile = (state: RootState) => state.profile.profile;
export const isLoaded = (state: RootState) => state.profile.loaded;

export const isProfessionalLocationsLoaded = (state: RootState) =>
  state.profile.professionalLocationsLoaded;
export const selectProfessionalLocations = (state: RootState) =>
  state.profile.professionalLocations;

export const isProfessionalIndustriesLoaded = (state: RootState) =>
  state.profile.professionalIndustriesLoaded;
export const selectProfessionalIndustries = (state: RootState) =>
  state.profile.professionalIndustries;

export const isProfessionalPositionsLoaded = (state: RootState) =>
  state.profile.professionalPositionsLoaded;
export const selectProfessionalPositions = (state: RootState) =>
  state.profile.professionalPositions;

export default profileSlice.reducer;
