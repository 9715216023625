import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

type ApplicationProps = {
  application: any;
};

const Application: React.FC<ApplicationProps> = ({ application }) => {
  const navigate = useNavigate();

  const onClickItem = (a: any) => {
    navigate(`/application/${a.number}`);
  };

  return (
    <ListItem
      alignItems="flex-start"
      onClick={() => {
        onClickItem(application);
      }}
    >
      <ListItemAvatar>
        <Avatar alt="Apply image" src={application.image} />
      </ListItemAvatar>
      <ListItemText
        primary={application.name}
        secondary={
          <span
            style={{
              marginTop: '.2em',
              fontSize: '1.1em',
            }}
          >
            <span>ステータス：</span>
            <span
              style={{
                color:
                  application.stageName === '採用'
                    ? 'green'
                    : application.stageName === 'キャンセル' ||
                      application.stageName === '不採用'
                    ? 'red'
                    : '#2175d9',
                fontWeight: 'bold',
              }}
            >
              {application.stageName}
            </span>
            <br />
            <span>応募日：</span>
            <span>
              {application.jobApplicationDate
                ? moment(application.jobApplicationDate).format('YYYY年M月D日')
                : null}
            </span>
          </span>
        }
      />
    </ListItem>
  );
};

export default Application;
