import * as React from 'react';
import { useEffect } from 'react';
import Job from '../components/job';
import {
  AppBar,
  Box,
  CircularProgress,
  Divider,
  List,
  Toolbar,
  Typography,
} from '@mui/material';

import { isLoaded, retrievePins, selectJobs } from '../features/job/jobSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { userId } from '../utils/liff';

export default function Pins() {
  const dispatch = useAppDispatch();
  const jobs = useAppSelector(selectJobs);
  const loaded = useAppSelector(isLoaded);

  useEffect(() => {
    document.title = 'ピン留めしたお仕事';

    if (!loaded) {
      dispatch(
        retrievePins({
          userId: userId(),
        }),
      );
    }
  }, [loaded, jobs]);

  return (
    <span>
      <AppBar
        position="static"
        elevation={0}
        style={{
          alignItems: 'center',
          backgroundColor: '#6ba5b4',
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            ピン留めしたお仕事
          </Typography>
        </Toolbar>
      </AppBar>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {!loaded ? (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <div>
            {jobs.length > 0 ? (
              jobs.map((job) => (
                <div key={job.number}>
                  <Job job={job} />
                  <Divider variant="inset" component="li" />
                </div>
              ))
            ) : (
              <div style={{ padding: '1em' }}>
                ピン留めしたお仕事はありません
              </div>
            )}
          </div>
        )}
      </List>
    </span>
  );
}
