import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import liff from '@line/liff';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL || '';
const CONTACT_API_KEY = process.env.REACT_APP_CONTACT_API_KEY || '';

export interface IdentificationState {
  jobs: any[];
  status: 'idle' | 'loading' | 'failed';
  loaded: boolean;
}

const initialState: IdentificationState = {
  jobs: [],
  status: 'idle',
  loaded: false,
};

//コンタクト更新
export const answerIdentification = createAsyncThunk(
  'answerIdentification',
  async (params: any) => {
    try {
      const response = await axios.patch(
        `${API_SERVER_URL}/line/v1/contacta/${params.userId}?apikey=${CONTACT_API_KEY}`,
        {
          lastname: params.values.lastname,
          firstname: params.values.firstname,
          line_birthday: new Date(params.values.birthday).setUTCHours(
            0,
            0,
            0,
            0,
          ),
          birth_year: params.values.birthday.substr(0, 4),
          birth_month: Number(params.values.birthday.substr(5, 2)) + '月',
          birth_day: Number(params.values.birthday.substr(8, 2)),
          staffcobe: params.values.staffCode,
          line_new_register: params.values.register,
        },
      );

      return response.data;
    } catch (e) {
      liff
        .sendMessages([
          {
            type: 'text',
            text: `通信エラーが発生しました。再度登録してください。 - ${e}`,
          },
        ])
        .then(() => {
          console.log('message sent');
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    }
  },
);

//コンタクト更新 新宿CS支店
export const answerIdentificationCs = createAsyncThunk(
  'answerIdentificationCs',
  async (params: any) => {
    const response = await axios.patch(
      `${API_SERVER_URL}/line/v1/contact/${params.userId}?apikey=${CONTACT_API_KEY}`,
      {
        lastname: params.values.lastname,
        firstname: params.values.firstname,
        line_birthday: new Date(params.values.birthday).setUTCHours(0, 0, 0, 0),
        birth_year: params.values.birthday.substr(0, 4),
        birth_month: Number(params.values.birthday.substr(5, 2)) + '月',
        birth_day: Number(params.values.birthday.substr(8, 2)),
        mobilephone: params.values.telNumber,
      },
    );
    return response.data;
  },
);

export const IdentificationCsSlice = createSlice({
  name: 'attendance',
  initialState,
  reducers: {
    hello: (state) => {
      console.log(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(answerIdentification.fulfilled, (state) => {
      state.status = 'idle';
      state.loaded = false;
    });
    builder.addCase(answerIdentificationCs.fulfilled, (state) => {
      state.status = 'idle';
      state.loaded = false;
    });
  },
});

export default IdentificationCsSlice;
