import * as React from 'react';
import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  List,
} from '@mui/material';

import {
  isHasMore,
  isLoaded,
  retrieveProfessionalJobs,
  selectJobs,
} from '../features/job/jobSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { userId } from '../utils/liff';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';

import liff from '@line/liff';
import ProfessionalJob from '../components/professionalJob';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const PROFILE_LIFF_ID =
  process.env.REACT_APP_PROFESSIONAL_PROFILE_LIFF_ID || '';

export default function ProfessionalFeed() {
  const dispatch = useAppDispatch();
  const jobs = useAppSelector(selectJobs);
  const loaded = useAppSelector(isLoaded);
  const hasMore = useAppSelector(isHasMore);

  let lang = 'ja';
  try {
    const search = useLocation().search;
    const query = queryString.parse(search);
    lang = String(query['lang']);
  } catch (e) {
    console.error(e);
  }

  const en = lang === 'en';

  useEffect(() => {
    document.title = en ? 'Recommended jobs' : 'おすすめのお仕事';

    if (!loaded) {
      dispatch(
        retrieveProfessionalJobs({
          userId: userId(),
          page: jobs.length,
          lang,
        }),
      );
    }
  }, [loaded, jobs]);

  const fetchMoreData = () => {
    setTimeout(() => {
      dispatch(
        retrieveProfessionalJobs({
          userId: userId(),
          page: jobs.length,
          lang,
        }),
      );
    }, 1500);
  };

  return (
    <span>
      <AppBar
        position="static"
        elevation={0}
        style={{
          backgroundColor: '#6ba5b4',
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {en ? 'Recommended jobs' : 'おすすめのお仕事'}
          </Typography>
          <IconButton
            size="small"
            edge="start"
            color="inherit"
            aria-label="other"
            onClick={() => {
              liff.openWindow({
                url: 'https://www.randstad.co.jp/tenshoku/',
                external: true,
              });
            }}
          >
            <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
              {en ? 'Other jobs' : 'その他の検索 '}
            </Typography>
            <OpenInNewIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <List sx={{ width: '100%', bgcolor: 'background.paper', paddingTop: 0 }}>
        {!loaded ? (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <span>
            {/*{profileLoaded ? (*/}
            {/*  profile.jobCategory ? null : (*/}
            {/*    <div*/}
            {/*      style={{*/}
            {/*        // padding: '0 0 0 .5em',*/}
            {/*        textAlign: 'center',*/}
            {/*        backgroundColor: '#ffb511',*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <IconButton*/}
            {/*        edge="start"*/}
            {/*        color="inherit"*/}
            {/*        aria-label="other"*/}
            {/*        onClick={() => {*/}
            {/*          liff.openWindow({*/}
            {/*            url: `https://liff.line.me/${PROFILE_LIFF_ID}`,*/}
            {/*          });*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        <Typography*/}
            {/*          variant="body2"*/}
            {/*          component="div"*/}
            {/*          sx={{ flexGrow: 1 }}*/}
            {/*        >*/}
            {/*          {`プロフィール・希望条件の設定が未完了です。`}*/}
            {/*          {<br />}*/}
            {/*          {`こちらから設定をしてください`}*/}
            {/*          <SavedSearchIcon*/}
            {/*            style={{*/}
            {/*              fontSize: '1em',*/}
            {/*            }}*/}
            {/*          />*/}
            {/*        </Typography>*/}
            {/*      </IconButton>*/}
            {/*    </div>*/}
            {/*  )*/}
            {/*) : null}*/}
            <InfiniteScroll
              dataLength={jobs.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={
                <Box
                  sx={{ display: 'flex', justifyContent: 'center', margin: 4 }}
                >
                  <CircularProgress />
                </Box>
              }
              endMessage={
                <div
                  style={{
                    padding: '1em 0 1em 1.5em',
                    textAlign: 'center',
                  }}
                >
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="other"
                    onClick={() => {
                      liff.openWindow({
                        url: `https://liff.line.me/${PROFILE_LIFF_ID}`,
                      });
                    }}
                  >
                    <Typography
                      variant="body2"
                      component="div"
                      sx={{ flexGrow: 1 }}
                    >
                      {en
                        ? 'On the Profile setting'
                        : `プロフィール・希望条件の設定画面で`}
                      {<br />}
                      {en
                        ? 'Click here to change your preferences'
                        : `希望条件を変更はこちら`}
                      <SavedSearchIcon
                        style={{
                          fontSize: '1em',
                        }}
                      />
                    </Typography>
                  </IconButton>
                </div>
              }
            >
              {jobs.map((job) => (
                <div key={job.number}>
                  <ProfessionalJob job={job} lang={lang} />
                  <Divider variant="inset" component="li" />
                </div>
              ))}
            </InfiniteScroll>
          </span>
        )}
      </List>
    </span>
  );
}
