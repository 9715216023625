import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL || '';

export const submitComplaint = createAsyncThunk(
  'submitComplaint',
  async (params: any) => {
    const response = await axios.post(
      `${API_SERVER_URL}/services/randstad/recommend/submit-complaint/${params.userId}`,
      params.values,
    );
    return response.data;
  },
);

export default submitComplaint;
