import * as React from 'react';
import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Job from '../components/job';
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  List,
} from '@mui/material';

import {
  isHasMore,
  isLoaded,
  retrieveJobs,
  selectJobs,
} from '../features/job/jobSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { userId } from '../utils/liff';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';

import liff from '@line/liff';
import {
  isLoaded as isLoadedProfile,
  retrieveProfile,
  selectProfile,
} from '../features/profile/profileSlice';

const PROFILE_LIFF_ID = process.env.REACT_APP_PROFILE_LIFF_ID || '';

export default function Feed() {
  const dispatch = useAppDispatch();
  const jobs = useAppSelector(selectJobs);
  const loaded = useAppSelector(isLoaded);
  const hasMore = useAppSelector(isHasMore);

  const profile: any = useAppSelector(selectProfile);
  const profileLoaded = useAppSelector(isLoadedProfile);

  useEffect(() => {
    document.title = 'おすすめのお仕事';

    if (!loaded) {
      dispatch(
        retrieveJobs({
          userId: userId(),
          page: jobs.length,
        }),
      );
    } else if (!profileLoaded) {
      dispatch(
        retrieveProfile({
          userId: userId(),
        }),
      );
    }
  }, [loaded, jobs, profileLoaded]);

  const fetchMoreData = () => {
    setTimeout(() => {
      dispatch(
        retrieveJobs({
          userId: userId(),
          page: jobs.length,
        }),
      );
    }, 1500);
  };

  return (
    <span>
      <AppBar
        position="static"
        elevation={0}
        style={{
          backgroundColor: '#6ba5b4',
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            おすすめのお仕事
          </Typography>
          <IconButton
            size="small"
            edge="start"
            color="inherit"
            aria-label="other"
            onClick={() => {
              liff.openWindow({
                url: 'https://www.randstad.co.jp/',
                external: true,
              });
            }}
          >
            <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
              {'その他の検索 '}
            </Typography>
            <OpenInNewIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <List sx={{ width: '100%', bgcolor: 'background.paper', paddingTop: 0 }}>
        {!loaded ? (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <span>
            {profileLoaded ? (
              profile.jobCategory ? null : (
                <div
                  style={{
                    // padding: '0 0 0 .5em',
                    textAlign: 'center',
                    backgroundColor: '#ffb511',
                  }}
                >
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="other"
                    onClick={() => {
                      liff.openWindow({
                        url: `https://liff.line.me/${PROFILE_LIFF_ID}`,
                      });
                    }}
                  >
                    <Typography
                      variant="body2"
                      component="div"
                      sx={{ flexGrow: 1 }}
                    >
                      {`プロフィール・希望条件の設定が未完了です。`}
                      {<br />}
                      {`こちらから設定をしてください`}
                      <SavedSearchIcon
                        style={{
                          fontSize: '1em',
                        }}
                      />
                    </Typography>
                  </IconButton>
                </div>
              )
            ) : null}
            <InfiniteScroll
              dataLength={jobs.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={
                <Box
                  sx={{ display: 'flex', justifyContent: 'center', margin: 4 }}
                >
                  <CircularProgress />
                </Box>
              }
              endMessage={
                <div
                  style={{
                    padding: '1em 0 1em 1.5em',
                    textAlign: 'center',
                  }}
                >
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="other"
                    onClick={() => {
                      liff.openWindow({
                        url: `https://liff.line.me/${PROFILE_LIFF_ID}`,
                      });
                    }}
                  >
                    <Typography
                      variant="body2"
                      component="div"
                      sx={{ flexGrow: 1 }}
                    >
                      {`プロフィール・希望条件の設定画面で`}
                      {<br />}
                      {`希望条件を変更はこちら`}
                      <SavedSearchIcon
                        style={{
                          fontSize: '1em',
                        }}
                      />
                    </Typography>
                  </IconButton>
                </div>
              }
            >
              {jobs.map((job) => (
                <div key={job.number}>
                  <Job job={job} />
                  <Divider variant="inset" component="li" />
                </div>
              ))}
            </InfiniteScroll>
          </span>
        )}
      </List>
    </span>
  );
}
