import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  isLoaded,
  isProfessionalIndustriesLoaded,
  isProfessionalLocationsLoaded,
  isProfessionalPositionsLoaded,
  retrieveProfile,
  retrieveProfileIndustries,
  retrieveProfileLocations,
  retrieveProfilePositions,
  selectProfessionalIndustries,
  selectProfessionalLocations,
  selectProfessionalPositions,
  selectProfile,
} from '../features/profile/profileSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { userId } from '../utils/liff';
import liff from '@line/liff';
import ProfessionalProfileForm from '../components/professionalForm';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export default function ProfessionalProfile() {
  const dispatch = useAppDispatch();
  const profile: any = useAppSelector(selectProfile);
  const loaded = useAppSelector(isLoaded);

  const locations: any = useAppSelector(selectProfessionalLocations);
  const professionalLocationsLoaded = useAppSelector(
    isProfessionalLocationsLoaded,
  );

  const industries: any = useAppSelector(selectProfessionalIndustries);
  const professionalIndustriesLoaded = useAppSelector(
    isProfessionalIndustriesLoaded,
  );

  const positions: any = useAppSelector(selectProfessionalPositions);
  const professionalPositionsLoaded = useAppSelector(
    isProfessionalPositionsLoaded,
  );

  let lang = 'ja';
  let retry = false;
  try {
    const search = useLocation().search;
    const query = queryString.parse(search);
    lang = String(query['lang']);
    retry = String(query['retry']) === 'true';
  } catch (e) {
    console.error(e);
  }
  if (profile) {
    lang = profile.lang;
  }

  const en = lang === 'en';
  const ja = lang === 'ja';

  useEffect(() => {
    document.title = en ? 'Profile' : ja ? 'プロフィール' : '';

    if (!loaded) {
      dispatch(
        retrieveProfile({
          userId: userId(),
        }),
      );
    }
    if (!professionalLocationsLoaded) {
      dispatch(retrieveProfileLocations());
    }
    if (!professionalIndustriesLoaded) {
      dispatch(retrieveProfileIndustries());
    }
    if (!professionalPositionsLoaded) {
      dispatch(retrieveProfilePositions());
    }
  }, [loaded]);

  const [open, setOpen] = React.useState(!userId());
  const handleClose = () => {
    setOpen(false);
    liff.closeWindow();
  };

  return (
    <span>
      <AppBar
        position="static"
        elevation={0}
        style={{
          alignItems: 'center',
          backgroundColor: '#6ba5b4',
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div">
            {en ? 'Profile' : ja ? 'プロフィール' : ''}
          </Typography>
        </Toolbar>
      </AppBar>
      {!profile ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}>
          <CircularProgress />
        </Box>
      ) : (
        <ProfessionalProfileForm
          profile={profile}
          lang={lang}
          locations={locations}
          industries={industries}
          positions={positions}
          retry={retry}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {en ? 'info' : 'お知らせ'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {en
              ? 'Available on the mobile version of LINE.'
              : 'スマートフォン版LINEでご利用になれます。'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
