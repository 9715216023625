import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';

import liff from '@line/liff';
import { RootState } from '../../app/store';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const JapaneseHolidays = require('japanese-holidays');

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL || '';
const API_KEY = process.env.REACT_APP_CONTACT_API_KEY || '';
const OBJECT_TYPE = process.env.REACT_APP_ADVANCE_PAY_OBJECT_TYPE || '';
const PIPELINE = process.env.REACT_APP_ADVANCE_PAY_PIPELINE || '';
const PIPELINE_STAGE_TODAY =
  process.env.REACT_APP_ADVANCE_PAY_PIPELINE_STAGE_TODAY || '';
const PIPELINE_STAGE_TOMORROW =
  process.env.REACT_APP_ADVANCE_PAY_PIPELINE_STAGE_TOMORROW || '';

export const createAdvancePay = createAsyncThunk(
  'createAdvancePay',
  async (params: any) => {
    try {
      let hs_pipeline_stage = '';
      const today = new Date();
      const day = today.getDay();

      if (JapaneseHolidays.isHoliday(today)) {
        hs_pipeline_stage = PIPELINE_STAGE_TODAY;
      } else if (day === 0 || day === 6) {
        hs_pipeline_stage = PIPELINE_STAGE_TODAY;
      } else {
        const now = moment();
        const am10 = moment().startOf('day').add(10, 'hours');
        if (now.isBefore(am10)) {
          hs_pipeline_stage = PIPELINE_STAGE_TODAY;
        } else {
          hs_pipeline_stage = PIPELINE_STAGE_TOMORROW;
        }
      }
      // console.log(hs_pipeline_stage);

      const data: any = {
        objectType: `${OBJECT_TYPE}`,
        associationType: '申請関連_to_コンタクト',
        properties: {
          // パイプライン
          hs_pipeline: PIPELINE,
          hs_pipeline_stage,

          // 基本情報
          name: `${params.values.lastname} ${params.values.firstname}`,
          lastname: params.values.lastname,
          firstname: params.values.firstname,
          staff_code: params.values.staffCode,

          // 前給申請
          advance_pay_type: params.values.advancePayType,
          advance_pay_branch: params.values.advancePayBranch,
          advance_pay_file_url: params.values.advancePayFileUrl,
          advance_pay_submit_date: Date.parse(moment().format('YYYY-MM-DD')),
        },
      };

      const response = await axios.post(
        `${API_SERVER_URL}/line/v1/custom-object/${params.userId}?apikey=${API_KEY}`,
        data,
      );

      const id = response.data.id;
      console.log(id);

      liff
        .sendMessages([
          {
            type: 'text',
            text: `[自動送信：前給申請]
フォームを登録しました。管理番号は${id}です。

スタッフコード:${params.values.staffCode}
姓：${params.values.lastname}　名：${params.values.firstname}
提出する勤怠：${params.values.advancePayType}
担当支店：${params.values.advancePayBranch}
申請日時：${moment().format('M月D日 HH時mm分')}
`,
          },
        ])
        .then(() => {
          console.log('message sent');
        })
        .catch((err) => {
          console.log(err);
        });

      return response.data;
    } catch (e) {
      console.error(e);
      alert(
        `エラーのため登録に失敗しました。管理者に連絡してください。 LINE ID: ${params.userId} - ${e}`,
      );
      window.location.reload();
    }
  },
);

export interface AdvancePayState {
  status: 'idle' | 'loading' | 'failed';
  created: boolean;
}

const initialState: AdvancePayState = {
  status: 'idle',
  created: false,
};

export const advancePaySlice = createSlice({
  name: 'advancePay',
  initialState,
  reducers: {
    hello: (state) => {
      console.log(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAdvancePay.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createAdvancePay.fulfilled, (state) => {
        state.status = 'idle';
        state.created = true;
      });
  },
});

export const isCreated = (state: RootState) => state.advancePay.created;

export default advancePaySlice.reducer;
