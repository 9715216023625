import * as React from 'react';
import { useEffect } from 'react';
import {
  AppBar,
  Box,
  CircularProgress,
  Divider,
  List,
  Toolbar,
  Typography,
} from '@mui/material';

import {
  isLoaded,
  retrieveApplications,
  retrieveCjcApplications,
  selectApplications,
} from '../features/application/applicationSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { userId } from '../utils/liff';
import Application from '../components/application';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export default function Applications() {
  const dispatch = useAppDispatch();
  const applications = useAppSelector(selectApplications);
  const loaded = useAppSelector(isLoaded);

  let isCjc = false;
  try {
    const search = useLocation().search;
    const query = queryString.parse(search);
    // console.log(query);
    isCjc = query['cjc'] === 'true';
  } catch (e) {
    console.error(e);
  }

  useEffect(() => {
    document.title = 'お仕事の応募状況';

    if (!loaded) {
      dispatch(
        isCjc
          ? retrieveCjcApplications({
              userId: userId(),
            })
          : retrieveApplications({
              userId: userId(),
            }),
      );
    }
  }, [loaded, applications]);

  return (
    <span>
      <AppBar
        position="static"
        elevation={0}
        style={{
          alignItems: 'center',
          backgroundColor: '#6ba5b4',
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            お仕事の応募状況
          </Typography>
        </Toolbar>
      </AppBar>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {!loaded ? (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <div>
            {applications.length > 0 ? (
              applications.map((application, index) => (
                <div key={index}>
                  <Application application={application} />
                  <Divider variant="inset" component="li" />
                </div>
              ))
            ) : (
              <div style={{ padding: '1em' }}>応募したお仕事はありません</div>
            )}
          </div>
        )}
      </List>
    </span>
  );
}
