import * as React from 'react';
import { useEffect } from 'react';
import {
  AppBar,
  Box,
  CircularProgress,
  Divider,
  List,
  Toolbar,
  Typography,
} from '@mui/material';

import {
  isLoaded,
  retrieveProfessionalPins,
  selectJobs,
} from '../features/job/jobSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { userId } from '../utils/liff';
import ProfessionalJob from '../components/professionalJob';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export default function ProfessionalPins() {
  const dispatch = useAppDispatch();
  const jobs = useAppSelector(selectJobs);
  const loaded = useAppSelector(isLoaded);

  let lang = 'ja';
  try {
    const search = useLocation().search;
    const query = queryString.parse(search);
    lang = String(query['lang']);
  } catch (e) {
    console.error(e);
  }

  const en = lang === 'en';

  useEffect(() => {
    document.title = en ? 'Saved jobs' : 'キープしたお仕事';

    if (!loaded) {
      dispatch(
        retrieveProfessionalPins({
          userId: userId(),
          lang,
        }),
      );
    }
  }, [loaded, jobs]);

  return (
    <span>
      <AppBar
        position="static"
        elevation={0}
        style={{
          alignItems: 'center',
          backgroundColor: '#6ba5b4',
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {en ? 'Saved jobs' : 'キープしたお仕事'}
          </Typography>
        </Toolbar>
      </AppBar>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {!loaded ? (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <div>
            {jobs.length > 0 ? (
              jobs.map((job) => (
                <div key={job.number}>
                  <ProfessionalJob job={job} lang={lang} />
                  <Divider variant="inset" component="li" />
                </div>
              ))
            ) : (
              <div style={{ padding: '1em' }}>
                {en ? 'No jobs saved.' : 'キープしたお仕事はありません'}
              </div>
            )}
          </div>
        )}
      </List>
    </span>
  );
}
