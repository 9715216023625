import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL || '';
const CONTACT_API_KEY = process.env.REACT_APP_CONTACT_API_KEY || '';

export interface AttendanceState {
  jobs: any[];
  status: 'idle' | 'loading' | 'failed';
  loaded: boolean;
}

const initialState: AttendanceState = {
  jobs: [],
  status: 'idle',
  loaded: false,
};

//コンタクト更新
export const answerAttendanceReport = createAsyncThunk(
  'answerAttendanceReport',
  async (params: any) => {
    const response = await axios.patch(
      `${API_SERVER_URL}/line/v1/contact/${params.userId}?apikey=${CONTACT_API_KEY}`,
      {
        line_attendance_report: params.values.report,
        line_attendance_reason_1:
          params.values.report === '欠勤' || params.values.report === '遅刻'
            ? params.values.reason_1
            : '',
        line_attendance_reason_2:
          params.values.reason_1 === '交通状況'
            ? ''
            : params.values.reason_1 === 'その他' ||
              (params.values.reason_2 === 'その他' && params.values.misc) ||
              params.values.report === '早退' ||
              params.values.report === '中抜け'
            ? params.values.misc
            : params.values.reason_2 === 'その他' && !params.values.misc
            ? 'その他（理由未入力）'
            : params.values.reason_2,
        line_fever_management:
          !(
            params.values.report === '早退' || params.values.report === '中抜け'
          ) &&
          !(
            params.values.reason_1 === 'その他' ||
            params.values.reason_2 === 'その他' ||
            params.values.reason_1 === '交通状況'
          )
            ? params.values.fever === 'なし（37.0度未満）' &&
              !(params.values.reason_2 === '発熱')
              ? params.values.fever
              : params.values.degrees_1 &&
                params.values.degrees_2 &&
                (params.values.fever === 'あり' ||
                  params.values.reason_2 === '発熱')
              ? `${params.values.degrees_1}.${params.values.degrees_2}℃`
              : params.values.degrees_1 &&
                (params.values.fever === 'あり' ||
                  params.values.reason_2 === '発熱')
              ? `${params.values.degrees_1}℃`
              : params.values.fever === 'あり' ||
                params.values.reason_2 === '発熱'
              ? 'あり（体温未入力）'
              : ''
            : '',
        line_paid_vacation_use:
          params.values.report === '欠勤' ? params.values.paid_vacation : '',
        line_hospital_schedule:
          params.values.report === '遅刻' &&
          (params.values.reason_1 === '自身の体調不良' ||
            params.values.reason_1 === '家族の体調不良')
            ? params.values.hospital_schedule
            : '',
      },
    );
    return response.data;
  },
);

//出勤時間更新
export const answerAttendanceScheduleTime = createAsyncThunk(
  'answerAttendanceScheduleTime',
  async (params: any) => {
    const response = await axios.patch(
      `${API_SERVER_URL}/line/v1/contact/${params.userId}?apikey=${CONTACT_API_KEY}`,
      {
        line_attendance_schedule_time:
          params.values === '未定' || params.values === ''
            ? params.values
            : `${params.values.getHours()}時${params.values.getMinutes()}分`,
      },
    );
    console.log(params.values);
    return response.data;
  },
);

//早退時間更新
export const answerLeaveEarlyScheduleTime = createAsyncThunk(
  'answerLeaveEarlyScheduleTime',
  async (params: any) => {
    const response = await axios.patch(
      `${API_SERVER_URL}/line/v1/contact/${params.userId}?apikey=${CONTACT_API_KEY}`,
      {
        line_leave_early_schedule_time:
          params.values === ''
            ? params.values
            : `${params.values.getHours()}時${params.values.getMinutes()}分`,
      },
    );
    return response.data;
  },
);

export const attendanceSlice = createSlice({
  name: 'attendance',
  initialState,
  reducers: {
    hello: (state) => {
      console.log(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(answerAttendanceReport.fulfilled, (state) => {
      state.status = 'idle';
      state.loaded = false;
    });
    builder.addCase(answerAttendanceScheduleTime.fulfilled, (state) => {
      state.status = 'idle';
      state.loaded = false;
    });
    builder.addCase(answerLeaveEarlyScheduleTime.fulfilled, (state) => {
      state.status = 'idle';
      state.loaded = false;
    });
  },
});

export default attendanceSlice.reducer;
