import * as React from 'react';
import { useEffect } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { userId } from '../utils/liff';
import liff from '@line/liff';
import {
  isLoaded,
  retrieveEntry,
  selectEntry,
} from '../features/entry/entrySlice';
import EntryConfirm from '../components/entryConfirm';
import Box from '@mui/material/Box';
import { CookiesProvider } from 'react-cookie';
import EntryForm from '../components/entryForm';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export default function Entry() {
  const dispatch = useAppDispatch();
  const entry: any = useAppSelector(selectEntry);
  const loaded = useAppSelector(isLoaded);

  let edit = false;
  try {
    const search = useLocation().search;
    const query = queryString.parse(search);
    edit = String(query['edit']) === 'true';
  } catch (e) {
    console.error(e);
  }

  console.log(edit);

  useEffect(() => {
    document.title = '入職書類フォーム';

    if (!loaded) {
      dispatch(
        retrieveEntry({
          userId: userId(),
        }),
      );
    }
  }, [loaded]);

  const [open, setOpen] = React.useState(!userId());
  const handleClose = () => {
    setOpen(false);
    liff.closeWindow();
  };

  return (
    <span>
      {!loaded ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}>
          <CircularProgress />
        </Box>
      ) : entry ? (
        edit ? (
          <CookiesProvider>
            <EntryForm entry={entry} edit={edit} />
          </CookiesProvider>
        ) : (
          <EntryConfirm entry={entry} />
        )
      ) : (
        <CookiesProvider>
          <EntryForm entry={entry} edit={edit} />
        </CookiesProvider>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'お知らせ'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            スマートフォン版LINEでご利用になれます。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
