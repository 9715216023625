import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import axios from 'axios';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL || '';

export interface JobState {
  jobs: any[];
  status: 'idle' | 'loading' | 'failed';
  loaded: boolean;
  hasMore: boolean;
}

const initialState: JobState = {
  jobs: [],
  status: 'idle',
  loaded: false,
  hasMore: true,
};

export const retrieveJobs = createAsyncThunk(
  'retrieveJobs',
  async (params: any) => {
    const response = await axios.get(
      `${API_SERVER_URL}/services/randstad/recommend/feed/full-time/${params.userId}/${params.page}`,
    );
    return response.data;
  },
);

export const retrieveProfessionalJobs = createAsyncThunk(
  'retrieveProfessionalJobs',
  async (params: any) => {
    const response = await axios.get(
      `${API_SERVER_URL}/services/randstad/recommend/feed/professional/${params.userId}/${params.page}?lang=${params.lang}`,
    );
    return response.data;
  },
);

export const retrieveSpotJobs = createAsyncThunk(
  'retrieveSpotJobs',
  async (params: any) => {
    const response = await axios.get(
      `${API_SERVER_URL}/services/randstad/recommend/feed/spot/${params.userId}/${params.page}`,
    );
    return response.data;
  },
);

export const retrieveJob = createAsyncThunk(
  'retrieveJob',
  async (params: any) => {
    const response = await axios.get(
      `${API_SERVER_URL}/services/randstad/recommend/job/full-time/${params.number}`,
    );
    return response.data;
  },
);

export const retrieveProfessionalJob = createAsyncThunk(
  'retrieveProfessionalJob',
  async (params: any) => {
    const response = await axios.get(
      `${API_SERVER_URL}/services/randstad/recommend/job/professional/${params.number}?lang=${params.lang}`,
    );
    return response.data;
  },
);

export const retrievePins = createAsyncThunk(
  'retrievePins',
  async (params: any) => {
    const response = await axios.get(
      `${API_SERVER_URL}/services/randstad/recommend/pins/full-time/${params.userId}`,
    );
    return response.data;
  },
);

export const retrieveProfessionalPins = createAsyncThunk(
  'retrieveProfessionalPins',
  async (params: any) => {
    const response = await axios.get(
      `${API_SERVER_URL}/services/randstad/recommend/pins/professional/${params.userId}?lang=${params.lang}`,
    );
    return response.data;
  },
);

export const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    hello: (state) => {
      console.log(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(retrieveJobs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(retrieveJobs.fulfilled, (state, action) => {
        state.status = 'idle';
        state.jobs = state.jobs.concat(action.payload);
        state.loaded = true;
        state.hasMore = action.payload.length > 0;
      });

    builder
      .addCase(retrieveProfessionalJobs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(retrieveProfessionalJobs.fulfilled, (state, action) => {
        state.status = 'idle';
        state.jobs = state.jobs.concat(action.payload);
        state.loaded = true;
        state.hasMore = action.payload.length > 0;
      });

    builder
      .addCase(retrieveJob.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(retrieveJob.fulfilled, (state, action) => {
        state.status = 'idle';
        state.jobs = action.payload
          ? state.jobs.concat(action.payload)
          : state.jobs;
        state.loaded = true;
      });

    builder
      .addCase(retrieveProfessionalJob.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(retrieveProfessionalJob.fulfilled, (state, action) => {
        state.status = 'idle';
        state.jobs = action.payload
          ? state.jobs.concat(action.payload)
          : state.jobs;
        state.loaded = true;
      });

    builder
      .addCase(retrievePins.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(retrievePins.fulfilled, (state, action) => {
        state.status = 'idle';
        state.jobs = state.jobs.concat(action.payload);
        state.loaded = true;
      });

    builder
      .addCase(retrieveProfessionalPins.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(retrieveProfessionalPins.fulfilled, (state, action) => {
        state.status = 'idle';
        state.jobs = state.jobs.concat(action.payload);
        state.loaded = true;
      });

    builder
      .addCase(retrieveSpotJobs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(retrieveSpotJobs.fulfilled, (state, action) => {
        state.status = 'idle';
        state.jobs = state.jobs.concat(action.payload);
        state.loaded = true;
        state.hasMore = action.payload.length > 0;
      });
  },
});

export const { hello } = jobSlice.actions;

export const selectJobs = (state: RootState) => state.job.jobs;
export const isLoaded = (state: RootState) => state.job.loaded;
export const isHasMore = (state: RootState) => state.job.hasMore;

export default jobSlice.reducer;
