import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Chip, Divider, Grid, Stack } from '@mui/material';
import { useMatch } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';

import BusinessIcon from '@mui/icons-material/Business';
import TrainIcon from '@mui/icons-material/Train';
import YenIcon from '@mui/icons-material/CurrencyYen';
import { selectApplications } from '../features/application/applicationSlice';
import moment from 'moment';

export default function ApplicationDetail() {
  const match = useMatch('/application/:number');
  const number = match?.params?.number;
  // console.log(number);

  const applications = useAppSelector(selectApplications);

  let application: any = {};
  if (applications.length > 0) {
    application = applications.find((a) => a.number === number) || {};
  }

  scrollTo(0, 0);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        style={{
          background: '#6ba5b4',
          padding: '1em 1em .5em .2em',
          color: '#ffffff',
        }}
      >
        <Grid item xs={3}>
          <img src={application.image} alt="Job image" height={66} />
        </Grid>
        <Grid item xs={9}>
          <Typography variant="h6" gutterBottom component="div">
            {application.name}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          padding: 22,
          backgroundColor: '#ffffff',
        }}
      >
        <Grid item xs={12}>
          <span
            style={{
              marginTop: '.2em',
              fontSize: '1.1em',
            }}
          >
            <span>ステータス：</span>
            <span
              style={{
                color:
                  application.stageName === '採用'
                    ? 'green'
                    : application.stageName === 'キャンセル' ||
                      application.stageName === '不採用'
                    ? 'red'
                    : '#2175d9',
                fontWeight: 'bold',
              }}
            >
              {application.stageName}
            </span>
            <br />
            <span>応募日：</span>
            <span>
              {application.jobApplicationDate
                ? moment(application.jobApplicationDate).format('YYYY年M月D日')
                : null}
            </span>
          </span>
          <br />
          <br />
          <Divider variant="fullWidth" />
          <br />
          <Stack
            direction="row"
            spacing={1}
            style={{
              padding: '0 0 1em 0',
            }}
          >
            {application.conditions
              ? application.conditions.map(
                  (condition: string, index: number) => {
                    return <Chip key={index} label={condition} />;
                  },
                )
              : null}
          </Stack>
          <div style={{ whiteSpace: 'pre-line' }}>
            {application.formattedDescription}
          </div>
          <br />
          <Divider variant="fullWidth" />
          <br />
        </Grid>
        <Grid item xs={12}>
          <React.Fragment>
            <div>
              <BusinessIcon style={{ fontSize: 12 }} />
              {application.industryType}
            </div>
            <div>
              <TrainIcon style={{ fontSize: 12 }} />
              {application.company}
            </div>
            <div>
              <YenIcon style={{ fontSize: 12 }} />
              {application.salary}
            </div>
          </React.Fragment>
          <br />
          <Divider variant="fullWidth" />
          <br />
        </Grid>
        <Grid item xs={12}>
          <React.Fragment>
            <div>
              {application.branch ? `求人担当: ${application.branch}` : ''}
            </div>
            <div>
              {application.telephone ? `TEL: ${application.telephone}` : ''}
            </div>
          </React.Fragment>
          <div style={{ height: '66px' }} />
        </Grid>
      </Grid>
    </Box>
  );
}
