import * as React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  answerIdentification,
  answerIdentificationCs,
} from '../features/identification/identificationSlice';
import { userId } from '../utils/liff';
import { useAppDispatch } from '../app/hooks';

import liff from '@line/liff';
import { MobileDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

type IdentificationFormProps = {
  profile: any;
  branch: string;
};

const IdentificationForm: React.FC<IdentificationFormProps> = ({
  profile,
  branch,
}) => {
  const dispatch = useAppDispatch();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      firstname: profile.firstname,
      lastname: profile.lastname,
      birthday: profile.birthday,
      staffCode: profile.staffCode,
      register: profile.register || '新規',
      telNumber: profile.telNumber,
    },
    errors: {},
    touched: {},
  });

  const handleChange = (event: any, field: any, value: any) => {
    // event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [field]: value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const [errMsg, setErrMsg] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleNext = () => {
    if (branch === 'shinjuku-cs') {
      if (formState.values.lastname === '') {
        setErrMsg('『姓』が未入力です。');
        handleOpen2();
        return;
      } else if (formState.values.firstname === '') {
        setErrMsg('『名』が未入力です。');
        handleOpen2();
        return;
      } else if (formState.values.birthday === '') {
        setErrMsg('『生年月日』が未入力です。');
        handleOpen2();
        return;
      } else if (formState.values.telNumber === '') {
        setErrMsg('『携帯電話番号』が未入力です。');
        handleOpen2();
        return;
      }
    } else {
      if (formState.values.lastname === '') {
        setErrMsg('『姓』が未入力です。');
        handleOpen2();
        return;
      } else if (formState.values.firstname === '') {
        setErrMsg('『名』が未入力です。');
        handleOpen2();
        return;
      } else if (formState.values.birthday === '') {
        setErrMsg('『生年月日』が未入力です。');
        handleOpen2();
        return;
      } else if (formState.values.staffCode === '') {
        setErrMsg('『スタッフコード』が未入力です。');
        handleOpen2();
        return;
      } else if (formState.values.register === '') {
        setErrMsg('『登録期間』が未選択です。');
        handleOpen2();
        return;
      }
    }
  };

  const handleClickSave = () => {
    setOpen(true);

    if (branch === 'shinjuku-cs') {
      dispatch(
        answerIdentificationCs({
          userId: userId(),
          values: formState.values,
        }),
      );
    } else {
      dispatch(
        answerIdentification({
          userId: userId(),
          values: formState.values,
        }),
      );
    }

    const register =
      formState.values.register === '新規'
        ? `登録から1ヶ月以内`
        : formState.values.register === '既存'
        ? `登録から1ヶ月以上経過`
        : '';

    const birthday = formState.values.birthday.replace(/-/g, '/');

    const text =
      branch === 'shinjuku-cs'
        ? `[本人登録]
名前: ${formState.values.lastname} ${formState.values.firstname}
生年月日: ${birthday}
携帯電話番号: ${formState.values.telNumber}`
        : `[本人登録]
名前: ${formState.values.lastname} ${formState.values.firstname}
生年月日: ${birthday}
スタッフコード: ${formState.values.staffCode}
登録期間: ${register}`;

    console.log(text);

    liff
      .sendMessages([
        {
          type: 'text',
          text,
        },
      ])
      .then(() => {
        console.log('message sent');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setOpen(false);
    liff.closeWindow();
  };

  // 携帯電話番号のエラー文字
  let errWord1 = '';
  if (formState.values.telNumber === '') {
    errWord1 = '必須項目';
  } else {
    errWord1 = '10桁以上入力してください';
  }

  // スタッフコードのエラー文字
  let errWord2 = '';
  if (formState.values.staffCode === '') {
    errWord2 = '必須項目';
  } else {
    errWord2 =
      branch === 'engineering'
        ? '6桁または8桁入力してください'
        : '8桁入力してください';
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          border: '1px solid rgb(0 0 0 / 12%)',
          margin: '1em 1em 1em 1em',
        }}
      >
        <div>
          <TextField
            id="standard-basic"
            label="姓"
            variant="standard"
            value={formState.values.lastname}
            error={formState.values.lastname === ''}
            onChange={(event) => {
              handleChange(event, 'lastname', event.target.value);
            }}
            sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
            placeholder="山田"
            helperText={formState.values.lastname === '' ? '必須項目' : false}
          />
          <TextField
            id="standard-basic"
            label="名"
            variant="standard"
            value={formState.values.firstname}
            error={formState.values.firstname === ''}
            onChange={(event) => {
              handleChange(event, 'firstname', event.target.value);
            }}
            sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
            placeholder="太郎"
            helperText={formState.values.firstname === '' ? '必須項目' : false}
          />
        </div>
        <div>
          {/*<TextField*/}
          {/*  id="birthday"*/}
          {/*  variant="standard"*/}
          {/*  className={'birthday-field'}*/}
          {/*  label="生年月日　※1"*/}
          {/*  type="date"*/}
          {/*  value={formState.values.birthday}*/}
          {/*  error={formState.values.birthday === ''}*/}
          {/*  onChange={(event) => {*/}
          {/*    console.log(event.target.value);*/}
          {/*    const targetDate = moment(event.target.value);*/}
          {/*    if (targetDate.isBefore(moment())) {*/}
          {/*      handleChange(event, 'birthday', event.target.value);*/}
          {/*    } else {*/}
          {/*      alert('選択した日付は無効です');*/}
          {/*      handleChange(event, 'birthday', '');*/}
          {/*    }*/}
          {/*  }}*/}
          {/*  sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}*/}
          {/*  InputLabelProps={{*/}
          {/*    shrink: true,*/}
          {/*  }}*/}
          {/*  helperText={formState.values.birthday === '' ? '必須項目' : false}*/}
          {/*/>*/}
          <MobileDatePicker
            label="生年月日　※1"
            inputFormat="yyyy/M/d"
            toolbarFormat="yyyy/M/d"
            value={formState.values.birthday || '2000/1/1'}
            maxDate={new Date()}
            onChange={(date) => {
              console.log(date);
              handleChange(
                {
                  target: {
                    name: 'birthday',
                  },
                },
                'birthday',
                moment(date).format('YYYY-MM-DD'),
              );
            }}
            renderInput={(params) => (
              <TextField
                variant="standard"
                sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!formState.values.birthday}
                helperText={!formState.values.birthday ? '必須項目' : false}
                {...params}
              />
            )}
          />
        </div>
        <div
          style={{
            display: branch === 'default' ? '' : 'none',
          }}
        >
          <TextField
            id="staff-code"
            variant="standard"
            label="スタッフコード　※2"
            type="tel"
            placeholder="00000000"
            value={formState.values.staffCode}
            error={String(formState.values.staffCode).length !== 8}
            inputProps={{ maxLength: '8' }}
            onChange={(event) => {
              const onlyNums = String(event.target.value).replace(
                /[^0-9]/g,
                '',
              );
              handleChange(event, 'staffCode', onlyNums);
            }}
            sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={
              String(formState.values.staffCode).length !== 8 ? errWord2 : false
            }
          />
        </div>
        <div
          style={{
            display: branch === 'engineering' ? '' : 'none',
          }}
        >
          <TextField
            id="staff-code"
            variant="standard"
            label="スタッフコード　※2"
            type="tel"
            placeholder="00000000"
            value={formState.values.staffCode}
            error={
              !(
                String(formState.values.staffCode).length === 6 ||
                String(formState.values.staffCode).length === 8
              )
            }
            inputProps={{ maxLength: '8' }}
            onChange={(event) => {
              const onlyNums = String(event.target.value).replace(
                /[^0-9]/g,
                '',
              );
              handleChange(event, 'staffCode', onlyNums);
            }}
            sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '35%' }}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={
              !(
                String(formState.values.staffCode).length === 6 ||
                String(formState.values.staffCode).length === 8
              )
                ? errWord2
                : false
            }
          />
        </div>
        <div
          style={{
            display: !(branch === 'shinjuku-cs') ? '' : 'none',
          }}
        >
          <FormControl sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '80%' }}>
            <FormLabel
              id="gendar-label"
              style={{ fontSize: '.8em', color: '#0f1941' }}
            >
              当社への登録は1ヶ月以内ですか？
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={formState.values.register}
              onChange={(event) => {
                handleChange(event, 'register', event.target.value);
              }}
            >
              <FormControlLabel
                value="新規"
                control={<Radio />}
                label="登録から1ヶ月以内"
              />
              <FormControlLabel
                value="既存"
                control={<Radio />}
                label="登録から1ヶ月以上経過"
              />
            </RadioGroup>
            <FormHelperText
              style={{
                display: formState.values.register === '' ? '' : 'none',
                color: '#d32f2f',
                marginLeft: 0,
              }}
            >
              必須項目
            </FormHelperText>
          </FormControl>
        </div>
        <div
          style={{
            display: branch === 'shinjuku-cs' ? '' : 'none',
          }}
        >
          <TextField
            id="telNumber"
            variant="standard"
            label="携帯電話番号"
            type="tel"
            placeholder="00000000000"
            value={formState.values.telNumber}
            error={String(formState.values.telNumber).length < 10}
            inputProps={{ maxLength: '11' }}
            onChange={(event) => {
              const onlyNums = String(event.target.value).replace(
                /[^0-9]/g,
                '',
              );
              handleChange(event, 'telNumber', onlyNums);
            }}
            sx={{ mt: 2, ml: 4, mr: 2, mb: 2, width: '50%' }}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={
              String(formState.values.telNumber).length < 10 ? errWord1 : false
            }
          />
        </div>
      </Box>
      <Box>
        <div style={{ display: 'flex' }}>
          <FormControl sx={{ mt: 1, ml: 4, mr: 0, mb: 0, width: '5%' }}>
            <FormLabel
              id="gendar-label"
              style={{ fontSize: '.8em', color: '#0f1941' }}
            >
              ※1
            </FormLabel>
          </FormControl>
          <FormControl sx={{ mt: 1, ml: 1, mr: 2, mb: 0, width: '75%' }}>
            <FormLabel
              id="gendar-label"
              style={{ fontSize: '.8em', color: '#0f1941' }}
            >
              Androidの方：生年月日の「年」を変更する場合は日付選択フォーム左上の西暦をタップすると「年」を変更・選択できます。
            </FormLabel>
          </FormControl>
        </div>
      </Box>
      <div style={{ display: branch === 'default' ? '' : 'none' }}>
        <Box>
          <div style={{ display: 'flex' }}>
            <FormControl sx={{ mt: 2, ml: 4, mr: 0, mb: 0, width: '5%' }}>
              <FormLabel
                id="gendar-label"
                style={{
                  fontSize: '.8em',
                  color: '#0f1941',
                  paddingTop: '2px',
                }}
              >
                ※2
              </FormLabel>
            </FormControl>
            <FormControl sx={{ mt: 2, ml: 1, mr: 2, mb: 0, width: '75%' }}>
              <FormLabel
                id="gendar-label"
                style={{ fontSize: '.8em', color: '#0f1941' }}
              >
                スタッフコードが不明な方は
                <Button
                  // sx={{ ml: 2 }}
                  onClick={() => {
                    liff.openWindow({
                      url: `https://www.randstad.co.jp/OCLSTF0040.do`,
                      external: true,
                    });
                  }}
                  style={{
                    fontSize: '1.1em',
                    fontWeight: 'bold',
                    padding: 0,
                  }}
                >
                  こちら
                </Button>
                から確認ください。
              </FormLabel>
            </FormControl>
          </div>
        </Box>
      </div>
      <div style={{ display: branch === 'engineering' ? '' : 'none' }}>
        <Box>
          <div style={{ display: 'flex' }}>
            <FormControl sx={{ mt: 2, ml: 4, mr: 0, mb: 0, width: '5%' }}>
              <FormLabel
                id="gendar-label"
                style={{
                  fontSize: '.8em',
                  color: '#0f1941',
                  paddingTop: '2px',
                }}
              >
                ※2
              </FormLabel>
            </FormControl>
            <FormControl sx={{ mt: 2, ml: 1, mr: 2, mb: 0, width: '75%' }}>
              <FormLabel
                id="gendar-label"
                style={{ fontSize: '.8em', color: '#0f1941' }}
              >
                スタッフコードが不明な方は
                <Button
                  // sx={{ ml: 2 }}
                  onClick={() => {
                    liff.openWindow({
                      url: `https://mypage.careo.jp/users/reminders/new`,
                      external: true,
                    });
                  }}
                  style={{
                    fontSize: '1.1em',
                    fontWeight: 'bold',
                    padding: 0,
                  }}
                >
                  こちら
                </Button>
                から確認ください。
              </FormLabel>
            </FormControl>
          </div>
        </Box>
      </div>
      <AppBar
        position="fixed"
        color="primary"
        sx={{ top: 'auto', bottom: 0, backgroundColor: '#1d6ad4' }}
      >
        <Toolbar>
          <Button
            color="inherit"
            aria-label="open drawer"
            sx={{ width: '100%' }}
            onClick={
              branch === 'shinjuku-cs'
                ? !formState.values.lastname ||
                  !formState.values.firstname ||
                  !formState.values.birthday ||
                  !formState.values.telNumber
                  ? handleNext
                  : handleClickSave
                : !formState.values.lastname ||
                  !formState.values.firstname ||
                  !formState.values.staffCode ||
                  !formState.values.birthday ||
                  !formState.values.register
                ? handleNext
                : handleClickSave
            }
          >
            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
              登録する
            </span>
          </Button>
        </Toolbar>
      </AppBar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'お知らせ'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            プロフィールを登録しました。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'必須項目が未入力'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default IdentificationForm;
